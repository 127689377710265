import {
	faDownload,
	faHeadset,
	faHome,
	faLanguage,
	faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { AppContext } from "./AppContext";
import { logoutAction } from "./actions/auth";
import { isNullOrUndefined } from "./utils";

export const useMenu = () => {
	const { t } = useTranslation();
	const { userProfile } = useSelector((state: any) => state.userProfile);
	const { setModalState } = useContext(AppContext);
	const dispatch = useDispatch();

	const handleSupportModal = () => {
		setModalState("support");
	};

	const handleMama4d = () => {
		// Open mama4d.app in a new tab when the page loads
		window.open('https://mama4d.app', '_blank', 'noopener,noreferrer');
	};

	const handleLogout = () => {
		Swal.fire({
			icon: "warning",
			text: t("Are you sure you want to logout?"),
			showCancelButton: true,
			confirmButtonColor: "#1e9fd9",
			cancelButtonColor: "grey",
			confirmButtonText: t("Confirm"),
			cancelButtonText: t("Cancel"),
			allowOutsideClick: false,
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(logoutAction());
			}
			return true;
		});
	};

	const menu = [
		{
			id: "home",
			label: "Home",
			value: isNullOrUndefined(userProfile) ? "/home" : "/lobby",
			icon: faHome,
			type: "link",
			access: true,
			mobile: true,
			offCanvas: true,
		},
		{
			id: "promotion",
			label: "Promotion",
			value: "/promotion",
			icon: faHome,
			type: "link",
			access: true,
			mobile: true,
			offCanvas: false,
		},
		{
			id: "download",
			label: "Download",
			value: "/download",
			icon: faDownload,
			type: "link",
			access: true,
			mobile: true,
			offCanvas: true,
		},
		{
			id: "support",
			label: "24/7 Support",
			value: handleSupportModal,
			icon: faHeadset,
			type: "action",
			access: true,
			mobile: true,
			offCanvas: false,
		},
		{
			id: "mama4d",
			label: "Mama4D",
			value: handleMama4d,
			icon: faHeadset,
			type: "action",
			access: true,
			mobile: false,
			offCanvas: true,
		},
		{
			id: "language",
			label: "Language",
			value: null,
			icon: faLanguage,
			type: "dropdown",
			access: true,
			mobile: false,
			offCanvas: true,
		},
		{
			id: "logout",
			label: "Logout",
			value: handleLogout,
			icon: faSignOut,
			type: "action",
			access: !isNullOrUndefined(userProfile),
			mobile: false,
			offCanvas: !isNullOrUndefined(userProfile),
		},
	];

	return menu;
};
